import launguage from "@/launguage";
import { DemandSupplyStore } from "@/store/modules";
import Vue from "vue";
import Router from "vue-router";
import i18n from "@/i18n";

const vm = new Vue();
async function canImprovedSeedRegister(next: any) {
  if (
    !DemandSupplyStore.ImprovedSeedSubsidySchedule ||
    !DemandSupplyStore.ImprovedSeedSubsidySchedule.registration_deadline
  ) {
    await DemandSupplyStore.getImprovedSeedSubsidySchedule();
  }
  if (
    DemandSupplyStore.ImprovedSeedSubsidySchedule &&
    DemandSupplyStore.ImprovedSeedSubsidySchedule.active &&
    DemandSupplyStore.ImprovedSeedSubsidySchedule.registration_deadline
  ) {
    if (DemandSupplyStore.ImprovedSeedSubsidySchedule.can_register) {
      next();
    } else {
      vm.$notify.error(i18n.t("noRegistrationOpeningError").toString());
      next({ name: "Home" });
    }
  } else {
    vm.$notify.error(i18n.t("noRegistrationOpeningError").toString());
    next({ name: "Home" });
  }
}
async function canSourceSeedRegister(next: any) {
  if (
    !DemandSupplyStore.SourceSeedSubsidySchedule ||
    !DemandSupplyStore.SourceSeedSubsidySchedule.registration_deadline
  ) {
    await DemandSupplyStore.getSourceSeedSubsidySchedule();
  }
  if (
    DemandSupplyStore.SourceSeedSubsidySchedule &&
    DemandSupplyStore.SourceSeedSubsidySchedule.active &&
    DemandSupplyStore.SourceSeedSubsidySchedule.registration_deadline
  ) {
    if (DemandSupplyStore.SourceSeedSubsidySchedule.can_register) {
      next();
    } else {
      vm.$notify.error(i18n.t("noRegistrationOpeningError").toString());
      next({ name: "Home" });
    }
  } else {
    vm.$notify.error(i18n.t("noRegistrationOpeningError").toString());
    next({ name: "Home" });
  }
}
const general = {
  path: "/:lang",
  component: () => import(/* webpackChunkName: "home" */ "@/views/layout/Layout.vue"),
  beforeEnter: launguage,
  // meta: {
  //     title: 'qwert',
  //     metaTags: [
  //       {
  //         name: 'image',
  //         content: 'The about page of our example app.'
  //       },
  //       {
  //         property: 'og:image',
  //         content: 'http://static01.nyt.com/images/2015/02/19/arts/international/19iht-btnumbers19A/19iht-btnumbers19A-facebookJumbo-v2.jpg'
  //       }
  //     ]
  //   },
  children: [
    {
      path: "",
      name: "Home",
      component: () => import(/* webpackChunkName: "home" */ "@/views/General/Homescreen.vue")
    },
    {
      path: "downloads",
      name: "Downloads",
      component: () => import(/* webpackChunkName: "home" */ "@/views/General/Downloads.vue")
    },
    {
      path: "privacy",
      name: "Privacy",
      component: () => import(/* webpackChunkName: "general" */ "@/views/PrivacyPolicy.vue")
    },
    {
      path: "privacypolicy",
      name: "AndroidPrivacyPolicy",
      component: () => import(/* webpackChunkName: "general" */ "@/views/AndroidPrivacyPolicy.vue")
    },
    {
      path: "about",
      name: "About",
      component: () => import(/* webpackChunkName: "home" */ "@/views/General/About.vue")
    },
    {
      path: "register",
      name: "Register",
      component: () =>
        import(/* webpackChunkName: "general" */ "@/views/Authscreen/RegisterUser.vue")
    },
    {
      path: "register/sourceseedproducer",
      name: "RegisterSourceSeedProducer",
      component: () =>
        import(
          /* webpackChunkName: "general" */ "@/views/Authscreen/Subsidy/RegisterSourceSeedProducer.vue"
        ),

      beforeEnter: (to: any, from: any, next: any) => {
        canSourceSeedRegister(next);
      }
    },
    {
      path: "register/improvedseedproducer",
      name: "RegisterImprovedSeedProducer",
      component: () =>
        import(
          /* webpackChunkName: "general" */ "@/views/Authscreen/Subsidy/RegisterImprovedSeedProducer.vue"
        ),
      beforeEnter: (to: any, from: any, next: any) => {
        canImprovedSeedRegister(next);
      }
    },
    {
      path: "register/improvedseeddistributor",
      name: "RegisterImprovedSeedDistributor",
      component: () =>
        import(
          /* webpackChunkName: "general" */ "@/views/Authscreen/Subsidy/RegisterImprovedSeedDistributor.vue"
        ),
      beforeEnter: (to: any, from: any, next: any) => {
        canImprovedSeedRegister(next);
      }
    },
    {
      path: "register/improvedseedconsumer",
      name: "RegisterImprovedSeedConsumer",
      component: () =>
        import(
          /* webpackChunkName: "general" */ "@/views/Authscreen/Subsidy/RegisterImprovedSeedConsumer.vue"
        ),
      beforeEnter: (to: any, from: any, next: any) => {
        canImprovedSeedRegister(next);
      }
    },
    {
      path: "register/akcuser",
      name: "RegisterAKCUser",
      component: () =>
        import(/* webpackChunkName: "general" */ "@/views/Authscreen/Subsidy/RegisterAKC.vue"),
      beforeEnter: (to: any, from: any, next: any) => {
        // canRegister(next);
        next();
      }
    },
    {
      path: "register/akcuser/:id",
      name: "EditAKCUser",
      component: () =>
        import(/* webpackChunkName: "general" */ "@/views/Authscreen/Subsidy/RegisterAKC.vue"),
      beforeEnter: (to: any, from: any, next: any) => {
        // canRegister(next);
        next();
      }
    },

    {
      path: "register/doaduser",
      name: "RegisterDOADUser",
      component: () =>
        import(/* webpackChunkName: "general" */ "@/views/Authscreen/Subsidy/RegisterDOAD.vue"),
      beforeEnter: (to: any, from: any, next: any) => {
        // canRegister(next);
        next();
      }
    },

    {
      path: "register/doaduser/:id",
      name: "EditDOADUser",
      component: () =>
        import(/* webpackChunkName: "general" */ "@/views/Authscreen/Subsidy/RegisterDOAD.vue"),
      beforeEnter: (to: any, from: any, next: any) => {
        // canRegister(next);
        next();
      }
    },

    {
      path: "register/done",
      name: "RegistrationCompleted",
      component: () =>
        import(
          /* webpackChunkName: "general" */ "@/views/Authscreen/Subsidy/RegistrationCompleted.vue"
        )
    },
    {
      path: "chooserole",
      name: "Chooserole",
      component: () =>
        import(/* webpackChunkName: "general" */ "@/views/Authscreen/Companyreg/Chooserole.vue")
    },
    {
      path: "registersteps",
      name: "Registersteps",
      component: () =>
        import(
          /* webpackChunkName: "general" */ "@/views/Authscreen/Companyreg/Registrationsteps.vue"
        )
    },
    {
      path: "improvedseedregistersteps",
      name: "ImprovedSeedRegisterSteps",
      component: () => import("@/views/Authscreen/Companyreg/ImprovedSeedRegistrationSteps.vue")
    },
    {
      path: "forgotpassword",
      name: "forgotpassword",
      component: () =>
        import(/* webpackChunkName: "general" */ "@/views/Authscreen/forgotpassword.vue")
    },
    {
      path: "profile",
      name: "Profile",
      component: () => import(/* webpackChunkName: "general" */ "@/views/Authscreen/profile.vue")
    },
    {
      path: "editprofile",
      name: "EditProfile",
      component: () =>
        import(/* webpackChunkName: "general" */ "@/views/Authscreen/EditProfile.vue")
    },
    {
      path: "activate/:uid/:token",
      name: "Activate",
      component: () => import(/* webpackChunkName: "general" */ "@/views/Authscreen/activate.vue")
    },
    {
      path: "varieties",
      name: "Variety",
      component: () =>
        import(/* webpackChunkName: "general" */ "@/views/SeedCatalog/CropVarieties.vue")
    },
    {
      path: "varietydetail",
      name: "VarietyDetail",
      component: () =>
        import(/* webpackChunkName: "general" */ "@/views/SeedCatalog/CropVarietyDetail.vue")
    },
    // {
    //   path: "scm",
    //   name: "scm",
    //   component: () => import("@/views/Admin/SCM.vue")
    // },
    {
      path: "catalog/:cropSlug/",
      name: "ListingScreen",
      component: () => import(/* webpackChunkName: "general" */ "@/views/ListingScreen.vue")
    },
    {
      path: "catalog/:cropSlug/:varietySlug/",
      name: "DetailScreen",
      component: () => import(/* webpackChunkName: "general" */ "@/views/DetailScreen.vue")
      //   meta: {
      //   title: 'qwert',
      //   metaTags: [
      //     {
      //       name: 'image',
      //       content: 'The about page of our example app.'
      //     },
      //     {
      //       property: 'og:image',
      //       content: "http://static01.nyt.com/images/2015/02/19/arts/international/19iht-btnumbers19A/19iht-btnumbers19A-facebookJumbo-v2.jpg"
      //     }
      //   ]
      // },
    },
    {
      path: "varietylist",
      name: "VarietyList",
      component: () => import(/* webpackChunkName: "general" */ "@/views/Varietylist.vue")
    },
    {
      path: "comparescreen",
      name: "comparescreen",
      component: () => import(/* webpackChunkName: "general" */ "@/views/CompareCrops.vue")
    },
    {
      path: "supplier",
      name: "SupplierSearch",
      component: () =>
        import(
          /* webpackChunkName: "general" */ "@/views/SupplierSearch/AdvancedSupplierSearch.vue"
        )
    },
    {
      path: "supplier/:userSlug/",
      name: "SupplierDetail",
      component: () =>
        import(/* webpackChunkName: "general" */ "@/views/SupplierSearch/SupplierDetail.vue")
    },
    {
      path: "approvedcompany",
      name: "ApprovedCompany",
      component: () =>
        import(/* webpackChunkName: "general" */ "@/views/General/ApprovedCompanyList.vue")
    },
    {
      path: "approvedcompany/:userSlug/",
      name: "ApprovedCompanyDetail",
      component: () =>
        import(/* webpackChunkName: "general" */ "@/views/SupplierSearch/SupplierDetail.vue")
    }
  ]
};

export default general;
