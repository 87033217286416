import JWTStore from "@/store/modules/jwt";
import launguage from "@/launguage"


const adminRoute = {
  path: "/:lang/user",
  component: () => import(/* webpackChunkName: "home" */ "@/views/layout/Layout.vue"),
  beforeEnter: launguage,
  children: [
    {
      path: "/",
      name: "User",
      meta: {
        needsAuth: false
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            localStorage.getItem("role") === ".."
          ) {
            next();
          }
          else {
            next({
              name: "Home"
            })
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "general" */ "@/views/user/Profile.vue")
    }
  ]
}


export default adminRoute
