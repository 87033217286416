import JWTStore from "@/store/modules/jwt";
import launguage from "@/launguage";
import Vue from "vue";
import i18n from "@/i18n";
import { DemandSupplyStore } from "@/store/modules";

const vm = new Vue();
async function canAdd(next: any) {
  if (
    !DemandSupplyStore.SubsidySchedule ||
    !DemandSupplyStore.SubsidySchedule.registration_deadline
  ) {
    await DemandSupplyStore.getSubsidySchedule();
  }
  if (
    DemandSupplyStore.SubsidySchedule &&
    DemandSupplyStore.SubsidySchedule.registration_deadline
  ) {
    if (DemandSupplyStore.SubsidySchedule.can_update_document) {
      next();
    } else {
      vm.$notify.error(i18n.t("noRegistrationOpeningError").toString());
      next({ name: "Home" });
    }
  } else {
    vm.$notify.error(i18n.t("noRegistrationOpeningError").toString());
    next({ name: "Home" });
  }
}

function roleChecker(roles: String[]) {
  for (let role of roles) {
    if (localStorage.getItem("role") === role) {
      return true;
    }
  }
  return false;
}

const companyRoute = {
  path: "/:lang/company",
  component: () => import(/* webpackChunkName: "home" */ "@/views/layout/Layout.vue"),
  beforeEnter: launguage,
  children: [
    {
      path: "menu",
      name: "CompanyMenu",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            roleChecker([
              "consumer",
              "supplier",
              "improved seed consumer",
              "source_seed_producer",
              "improved_seed_producer",
              "improved_seed_distributor",
              "improved_seed_consumer"
            ])
          ) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "company" */ "@/views/Company/CompanyUserMenu.vue")
    },
    {
      path: "addsupply",
      name: "AddSupply",
      meta: {
        // needsAuth must be false
        needsAuth: false
      },
      beforeEnter: (to: any, from: any, next: any) => {
        canAdd(next);
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            roleChecker([
              "supplier",
              "source_seed_producer",
              "improved_seed_producer",
              "improved_seed_distributor"
            ])
          ) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "company" */ "@/views/Company/AddSupply.vue")
    },
    {
      path: "addsupplydocuments",
      name: "AddSupplyDocument",
      meta: {
        // needsAuth must be false
        needsAuth: false
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            roleChecker([
              "consumer",
              "supplier",
              "improved seed consumer",
              "source_seed_producer",
              "improved_seed_producer",
              "improved_seed_distributor",
              "improved_seed_consumer"
            ])
          ) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "company" */ "@/views/Company/AddSupplyDocuments.vue")
    },
    {
      path: "subsidyforms",
      name: "SubsidyFormDownload",
      meta: {
        // needsAuth must be false
        needsAuth: false
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            roleChecker([
              "consumer",
              "supplier",
              "improved seed consumer",
              "source_seed_producer",
              "improved_seed_producer",
              "improved_seed_distributor",
              "improved_seed_consumer"
            ])
          ) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "company" */ "@/views/Company/SubsidyFormDownload.vue")
    },
    {
      path: "adddemand",
      name: "AddDemand",
      meta: {
        // needsAuth must be false
        needsAuth: false
      },
      beforeEnter: (to: any, from: any, next: any) => {
        canAdd(next);
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            roleChecker([
              "consumer",
              "supplier",
              "improved seed consumer",
              "source_seed_producer",
              "improved_seed_producer",
              "improved_seed_distributor",
              "improved_seed_consumer"
            ])
          ) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "company" */ "@/views/Company/AddDemand.vue")
    },

    {
      path: "myallocation",
      name: "MyAllocation",
      meta: {
        // needsAuth must be false
        needsAuth: false
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (JWTStore.loggedIn && roleChecker(["improved_seed_producer"])) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "company" */ "@/views/Company/MyAllocation.vue")
    },
    {
      path: "supplylist",
      name: "SupplyList",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            roleChecker([
              "supplier",
              "source_seed_producer",
              "improved_seed_producer",
              "improved_seed_distributor"
            ])
          ) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "company" */ "@/views/Company/SupplyList.vue")
    },
    {
      path: "demandlist",
      name: "DemandList",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            roleChecker([
              "consumer",
              "supplier",
              "improved seed consumer",
              "source_seed_producer",
              "improved_seed_producer",
              "improved_seed_distributor",
              "improved_seed_consumer"
            ])
          ) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "company" */ "@/views/Company/DemandList.vue")
    },
    {
      path: "supplylist/:clusterId/",
      name: "SupplyDetail",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            roleChecker([
              "supplier",
              "source_seed_producer",
              "improved_seed_producer",
              "improved_seed_distributor"
            ])
          ) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "company" */ "@/views/Company/SupplyDetail.vue")
    },
    {
      path: "demandlist/:clusterId/",
      name: "DemandDetail",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            roleChecker([
              "consumer",
              "supplier",
              "improved seed consumer",
              "source_seed_producer",
              "improved_seed_producer",
              "improved_seed_distributor",
              "improved_seed_consumer"
            ])
          ) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "company" */ "@/views/Company/DemandDetail.vue")
    },
    {
      path: "allocation",
      name: "AllocationList",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            roleChecker([
              "consumer",
              "supplier",
              "improved seed consumer",
              "source_seed_producer",
              "improved_seed_producer",
              "improved_seed_distributor",
              "improved_seed_consumer"
            ])
          ) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "company" */ "@/views/Company/AllocationList.vue")
    },
    {
      path: "preferences",
      name: "Preferences",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            roleChecker([
              "consumer",
              "supplier",
              "improved seed consumer",
              "source_seed_producer",
              "improved_seed_producer",
              "improved_seed_distributor",
              "improved_seed_consumer"
            ])
          ) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "company" */ "@/views/Company/Preferences.vue")
    },
    {
      path: "distribution",
      name: "DistributionList",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            roleChecker([
              "supplier",
              "source_seed_producer",
              "improved_seed_producer",
              "improved_seed_distributor"
            ])
          ) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "company" */ "@/views/Company/DistributionList.vue")
    },
    {
      path: "distribution/:supplyId/",
      name: "DistributionDetail",
      meta: {
        needsAuth: false
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            roleChecker([
              "supplier",
              "source_seed_producer",
              "improved_seed_producer",
              "improved_seed_distributor"
            ])
          ) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "company" */ "@/views/Company/DistributionDetail.vue")
    },

    {
      path: "addpurchase",
      name: "AddPurchase",
      meta: {
        // needsAuth must be false
        needsAuth: false
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (JWTStore.loggedIn && roleChecker(["improved_seed_distributor"])) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "company" */ "@/views/Company/AddPurchase.vue")
    },
    {
      path: "purchaselist",
      name: "PurchaseList",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (JWTStore.loggedIn && roleChecker(["improved_seed_distributor"])) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "company" */ "@/views/Company/PurchaseList.vue")
    },
    {
      path: "purchaselist/:clusterId/",
      name: "PurchaseDetail",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (JWTStore.loggedIn && roleChecker(["improved_seed_distributor"])) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "company" */ "@/views/Company/PurchaseDetail.vue")
    },

    {
      path: "addsale",
      name: "AddSale",
      meta: {
        // needsAuth must be false
        needsAuth: false
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (JWTStore.loggedIn && roleChecker(["improved_seed_distributor"])) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "company" */ "@/views/Company/AddSale.vue")
    },
    {
      path: "salelist",
      name: "SaleList",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (JWTStore.loggedIn && roleChecker(["improved_seed_distributor"])) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "company" */ "@/views/Company/SaleList.vue")
    },
    {
      path: "salelist/:clusterId/",
      name: "SaleDetail",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (JWTStore.loggedIn && roleChecker(["improved_seed_distributor"])) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "company" */ "@/views/Company/SaleDetail.vue")
    },
    {
      path: "municipality_wise_allocation_report",
      name: "CompanyMunicipalityWiseAllocationReport",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (JWTStore.loggedIn && roleChecker(["improved_seed_consumer"])) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(
          /* webpackChunkName: "admin" */ "@/views/Admin/Reports/MunicipalityWiseAllocationReport.vue"
        )
    },
    {
      path: "verifyAllocation",
      name: "CompanyVerifyAllocation",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        // canAdd(next);
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (JWTStore.loggedIn && roleChecker(["DLAO", "improved_seed_consumer"])) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "company" */ "@/views/Admin/VerifyAllocation.vue")
    }
  ]
};

export default companyRoute;
