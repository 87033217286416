import JWTStore from "@/store/modules/jwt";
import launguage from "@/launguage";
import { roleChecker } from "@/utils/jwt";

const adminRoute = {
  path: "/:lang/admin",
  component: () => import(/* webpackChunkName: "home" */ "@/views/layout/Layout.vue"),
  beforeEnter: launguage,
  children: [
    {
      path: "Adminmenu",
      name: "Adminmenu",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            roleChecker([
              "SQCCAdmin",
              "SSSMAdmin",
              "ISSMAdmin",
              "ISMAdmin",
              "DLAO",
              "DOADViewer",
              "DOAAdmin",
              "MoALD"
            ])
          ) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/Adminmenu.vue")
    },
    {
      path: "chooseallocation",
      name: "ChooseAllocation",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (JWTStore.loggedIn && roleChecker(["SQCCAdmin", "ISSMAdmin", "ISMAdmin"])) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/ChooseAllocation.vue")
    },
    {
      path: "allocation",
      name: "Allocation",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (JWTStore.loggedIn && roleChecker(["SQCCAdmin", "ISSMAdmin", "ISMAdmin"])) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/Allocation.vue")
    },
    {
      path: "allocationlist",
      name: "AdminAllocationList",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (JWTStore.loggedIn && roleChecker(["SQCCAdmin", "ISSMAdmin", "ISMAdmin"])) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/AllocationList.vue")
    },
    {
      path: "savedallocationlist",
      name: "AdminSavedAllocationList",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (JWTStore.loggedIn && roleChecker(["SQCCAdmin", "ISSMAdmin", "ISMAdmin"])) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "admin" */ "@/views/Admin/SavedAllocationList.vue")
    },
    {
      path: "allocationdetail",
      name: "AdminAllocationDetail",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (JWTStore.loggedIn && roleChecker(["SQCCAdmin", "ISSMAdmin", "ISMAdmin"])) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/AllocationDetail.vue")
    },
    {
      path: "crop",
      name: "AddCrop",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (JWTStore.loggedIn && roleChecker(["SQCCAdmin"])) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/Crop.vue")
    },
    {
      path: "cropvarietyvalue",
      name: "CropVarietyValue",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (JWTStore.loggedIn && roleChecker(["SQCCAdmin"])) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/CropVarietyValue.vue")
    },
    {
      path: "cropaddnewvariety",
      name: "CropAddNewVariety",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (JWTStore.loggedIn && roleChecker(["SQCCAdmin"])) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/CropAddNewVariety.vue")
    },
    {
      path: "crop",
      name: "Crop",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          console.log("first");
          console.log(JWTStore.loggedIn);
          console.log(localStorage.getItem("role"));
          if (JWTStore.loggedIn && roleChecker(["SQCCAdmin"])) {
            console.log("second");
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/Crop.vue")
    },
    {
      path: "cropvarietyvaluelist",
      name: "CropVarietyValueList",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (JWTStore.loggedIn && roleChecker(["SQCCAdmin"])) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "admin" */ "@/views/Admin/CropVarietyValueList.vue")
    },
    {
      path: "userlist",
      name: "UserList",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (JWTStore.loggedIn && roleChecker(["SQCCAdmin"])) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/UserSettings.vue")
    },

    {
      path: "users/:userSlug/",
      name: "UserDetail",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            roleChecker(["SQCCAdmin", "SSSMAdmin", "ISSMAdmin", "DLAO", "DOAAdmin"])
          ) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/UserDetail.vue")
    },

    {
      path: "pendingusers",
      name: "PendingUsers",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (JWTStore.loggedIn && roleChecker(["SQCCAdmin", "SSSMAdmin", "ISSMAdmin"])) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/PendingUserList.vue")
    },
    {
      path: "approvedusers",
      name: "ApprovedUsers",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            roleChecker(["SQCCAdmin", "SSSMAdmin", "ISSMAdmin", "DLAO", "DOAAdmin"])
          ) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/ApprovedUserList.vue")
    },
    {
      path: "rejectedusers",
      name: "RejectedUsers",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (JWTStore.loggedIn && roleChecker(["SQCCAdmin", "SSSMAdmin", "ISSMAdmin"])) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/RejectedUserList.vue")
    },
    {
      path: "uploaded-documents",
      name: "UploadedDocuments",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (JWTStore.loggedIn && roleChecker(["SQCCAdmin", "SSSMAdmin", "ISSMAdmin"])) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/DocumentList.vue")
    },
    {
      path: "viewerlist",
      name: "ViewerList",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (JWTStore.loggedIn && roleChecker(["ISSMAdmin"])) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/ViewerList.vue")
    },

    {
      path: "doadviewerlist",
      name: "DOADViewerList",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (JWTStore.loggedIn && roleChecker(["ISSMAdmin"])) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/DOADViewerList.vue")
    },

    {
      path: "rolelist",
      name: "RoleList",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (JWTStore.loggedIn && roleChecker(["SQCCAdmin"])) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/UserInRoleList.vue")
    },
    {
      path: "demandsupplycalendar",
      name: "DemandSupplyCalendar",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (JWTStore.loggedIn && roleChecker(["SQCCAdmin"])) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "admin" */ "@/views/Admin/DemandSupplySchedule.vue")
    },
    {
      path: "adddemandsupplygroup",
      name: "AddDemandSupplyGroup",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (JWTStore.loggedIn && roleChecker(["SQCCAdmin"])) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "admin" */ "@/views/Admin/AddDemandSupplyGroup.vue")
    },
    {
      path: "RegisterseedComp",
      name: "Seedcomregister",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (JWTStore.loggedIn && localStorage.getItem("role") === "SQCCAdmin") {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/Seedcomregister.vue")
    },
    {
      path: "supplylist",
      name: "AdminSupplyList",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            roleChecker([
              "SQCCAdmin",
              "SSSMAdmin",
              "ISSMAdmin",
              "ISMAdmin",
              "DLAO",
              "DOADViewer",
              "MoALD"
            ])
          ) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/SupplyList.vue")
    },
    {
      path: "saleslist",
      name: "AdminSalesList",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            roleChecker([
              "SQCCAdmin",
              "SSSMAdmin",
              "ISSMAdmin",
              "ISMAdmin",
              "DLAO",
              "DOADViewer",
              "MoALD"
            ])
          ) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "admin" */ "@/views/Admin/SourceSalesBillList.vue")
    },
    {
      path: "demandlist",
      name: "AdminDemandList",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            roleChecker([
              "SQCCAdmin",
              "SSSMAdmin",
              "ISSMAdmin",
              "ISMAdmin",
              "DLAO",
              "DOADViewer",
              "MoALD"
            ])
          ) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/DemandList.vue")
    },
    {
      path: "approveallocationlist",
      name: "AdminApproveAllocationList",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (JWTStore.loggedIn && roleChecker(["DLAO"])) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "admin" */ "@/views/Admin/ApproveAllocationList.vue")
    },
    {
      path: "subsidylist",
      name: "AdminSubsidyList",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (JWTStore.loggedIn && roleChecker(["SQCCAdmin", "SSSMAdmin", "ISSMAdmin"])) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/SubsidyList.vue")
    },

    {
      path: "subsidyschedule",
      name: "AdminSubsidySchedule",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (JWTStore.loggedIn && roleChecker(["SQCCAdmin", "SSSMAdmin", "ISSMAdmin"])) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/SubsidySchedule.vue")
    },

    {
      path: "salelist",
      name: "AdminSaleList",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            roleChecker(["SQCCAdmin", "SSSMAdmin", "ISSMAdmin", "ISMAdmin"])
          ) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/SaleList.vue")
    },
    {
      path: "purchaselist",
      name: "AdminPurchaseList",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            roleChecker(["SQCCAdmin", "SSSMAdmin", "ISSMAdmin", "ISMAdmin"])
          ) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/PurchaseList.vue")
    },
    {
      path: "report",
      name: "ReportMenu",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            roleChecker([
              "SQCCAdmin",
              "SSSMAdmin",
              "ISSMAdmin",
              "ISMAdmin",
              "DLAO",
              "DOADViewer",
              "DOAAdmin",
              "MoALD"
            ])
          ) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "admin" */ "@/views/Admin/Reports/ReportMenu.vue")
    },
    {
      path: "municipality_wise_allocation_report",
      name: "MunicipalityWiseAllocationReport",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            roleChecker(["SQCCAdmin", "SSSMAdmin", "ISSMAdmin", "ISMAdmin"])
          ) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(
          /* webpackChunkName: "admin" */ "@/views/Admin/Reports/MunicipalityWiseAllocationReport.vue"
        )
    },
    {
      path: "supply_demand_report",
      name: "SupplyVsDemandReport",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            roleChecker(["SQCCAdmin", "SSSMAdmin", "ISSMAdmin", "ISMAdmin", "DOADViewer", "DLAO"])
          ) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "admin" */ "@/views/Admin/Reports/SupplyVsDemandReport.vue")
    },
    {
      path: "balancesheet",
      name: "BalanceSheet",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            roleChecker([
              "SQCCAdmin",
              "ISSMAdmin",
              "sqcc_viewer",
              "DLAO",
              "DOADViewer",
              "DOAAdmin",
              "MoALD"
            ])
          ) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "admin" */ "@/views/Admin/Reports/BalanceSheet.vue")
    },
    {
      path: "subsidy-report",
      name: "SubsidyReport",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            roleChecker([
              "SQCCAdmin",
              "SSSMAdmin",
              "ISSMAdmin",
              "ISMAdmin",
              "DLAO",
              "DOADViewer",
              "DOAAdmin",
              "MoALD"
            ])
          ) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "admin" */ "@/views/Admin/Reports/SubsidyReport.vue")
    },
    {
      path: "supply-report",
      name: "SupplyReport",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            roleChecker([
              "SQCCAdmin",
              "SSSMAdmin",
              "ISSMAdmin",
              "DLAO",
              "DOADViewer",
              "DOAAdmin",
              "MoALD"
            ])
          ) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "admin" */ "@/views/Admin/Reports/SupplyReport.vue")
    },
    {
      path: "demand-report",
      name: "DemandReport",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            roleChecker([
              "SQCCAdmin",
              "SSSMAdmin",
              "ISSMAdmin",
              "DLAO",
              "DOADViewer",
              "DOAAdmin",
              "MoALD"
            ])
          ) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "admin" */ "@/views/Admin/Reports/DemandReport.vue")
    },
    {
      path: "user-report",
      name: "UserReport",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (JWTStore.loggedIn && roleChecker(["SQCCAdmin", "ISSMAdmin", "DOADViewer"])) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "admin" */ "@/views/Admin/Reports/UserReport.vue")
    },
    {
      path: "adhocreport",
      name: "AdHocReport",
      meta: {
        needsAuth: false
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            roleChecker(["SQCCAdmin", "SSSMAdmin", "ISSMAdmin", "ISMAdmin"])
          ) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "adhoc" */ "@/views/Admin/Reports/AdHocReport.vue")
    },
    {
      path: "categoryreport",
      name: "CategoryReport",
      meta: {
        needsAuth: false
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (JWTStore.loggedIn && roleChecker(["SQCCAdmin"])) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "admin" */ "@/views/Admin/Reports/CategoryReport.vue")
    },
    {
      path: "allocationreport",
      name: "AllocationReport",
      meta: {
        needsAuth: false
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (JWTStore.loggedIn && roleChecker(["SQCCAdmin", "ISSMAdmin", "ISMAdmin"])) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "admin" */ "@/views/Admin/Reports/AllocationReportList.vue")
    },
    {
      path: "demandforecastreport",
      name: "DemandForecastReport",
      meta: {
        needsAuth: false
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (JWTStore.loggedIn && roleChecker(["SQCCAdmin", "ISSMAdmin", "ISMAdmin"])) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "admin" */ "@/views/Admin/Reports/DemandForecastReport.vue")
    },
    {
      path: "unsoldAllocationReport",
      name: "unsoldAllocationReport",
      meta: {
        needsAuth: false
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            roleChecker(["SQCCAdmin", "SSSMAdmin", "ISSMAdmin", "ISMAdmin"])
          ) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "admin" */ "@/views/Admin/Reports/UnsoldAllocationReport.vue")
    },

    {
      path: "unsoldAllocationReportDetail",
      name: "unsoldAllocationReportDetail",
      meta: {
        needsAuth: false
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            roleChecker(["SQCCAdmin", "SSSMAdmin", "ISSMAdmin", "ISMAdmin"])
          ) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(
          /* webpackChunkName: "admin" */ "@/views/Admin/Reports/UnsoldAllocationReportDetail.vue"
        )
    },
    {
      path: "transactionreport",
      name: "TransactionReport",
      meta: {
        needsAuth: false
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (JWTStore.loggedIn && localStorage.getItem("role") === "SQCCAdmin") {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "admin" */ "@/views/Admin/Reports/TransactionReport.vue")
    },
    {
      path: "sendMailLogs",
      name: "SendMailLogs",
      meta: {
        needsAuth: false
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            roleChecker(["SQCCAdmin", "SSSMAdmin", "ISSMAdmin", "ISMAdmin"])
          ) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/SendMailLogs.vue")
    },
    {
      path: "dashboard",
      name: "Dashboard",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            roleChecker(["SQCCAdmin", "SSSMAdmin", "ISSMAdmin", "ISMAdmin"])
          ) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/Dashboard.vue")
    },
    {
      path: "commentlist",
      name: "AdminCommentList",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (
            JWTStore.loggedIn &&
            roleChecker(["SQCCAdmin", "SSSMAdmin", "ISSMAdmin", "ISMAdmin"])
          ) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () => import(/* webpackChunkName: "admin" */ "@/views/Admin/CommentList.vue")
    },
    {
      path: "verifyAllocation",
      name: "VerifyAllocation",
      meta: {
        needsAuth: true
      },
      beforeEnter: (to: any, from: any, next: any) => {
        // canAdd(next);
        if (to.matched.some((record: any) => record.meta.needsAuth)) {
          if (JWTStore.loggedIn && roleChecker(["DLAO"])) {
            next();
          } else {
            next({
              name: "Home"
            });
          }
        } else {
          next();
        }
      },
      component: () =>
        import(/* webpackChunkName: "company" */ "@/views/Admin/VerifyAllocation.vue")
    }
  ]
};

export default adminRoute;
